@tailwind base;
@tailwind components;
@tailwind utilities;

::after, ::before, *{
	padding: 0;
	margin: 0 ;
	box-sizing: border-box;
}


html {
	/* scroll-behavior: auto;   */
}

body, html, p, h1, h2, h3, h4, h5, h6{
	font-family: 'Lato', serif !important;

}

section{
	/* margin: 5rem 0; */
}

.video_height
{
	height: auto;
}

.video-wrapper {
	position: relative;
	width: 100%;
	max-width: 100%;
	height: 100%;
	padding: 41.7% 0 0 0; 
	/* 100%/16*9 = 56.25% = Aspect ratio 16:9 */
	overflow: hidden;
	border: 0;
  }
  
  .video-wrapper video {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	object-fit: cover;
	filter: brightness(0.7);
	border: 1px solid black;
  }

  @media only screen and (max-width: 786px) {
	.video_height {
	  height: 50vh;
	}
  }
/* 
button{
	border-radius: ".8rem" !important;
	border-color: #333 !important;
	
}

.primary_button{
	background-color: '#ffdd00' !important;
	color: 'black' !important
}

.secondary_button{
	background: 'white';
	color: 'black' !important
} */
