.container {
	font-family: sans-serif;
  }
  
  .dates {
	text-align: left;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	margin-top: 3rem;
  }
  
  .title {
	color: navy;
	font-weight: 800;
	margin: 0;
  }
  
  .card-container {
	width: 20rem;
	height: 20rem;
	position: relative;
	margin-left: 1rem;
	margin-right: 1rem;
  }
  
  .card {
	overflow: hidden;
	box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.2);
	padding: 2rem;
	border-radius: 1rem;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	position: relative;
	cursor: pointer;
  }
  
  .expanded {
	width: 25rem;
	height: 25rem;
	background-color: navy;
	position: relative;
	left: 0%;
	opacity: 1;
	top: 150%;
  }
  
  .secondary {
	background-color: white;
	height: 2.5rem;
	padding: 0.5rem;
	justify-content: center;
	width: 8rem;
	margin-left: 1rem;
  }
  
  .compact {
	width: 15rem;
	height: 15rem;
	padding: 0.5rem;
	border-radius: 1.5rem;
	color: black;
  }
  
  .expanded .title {
	font-size: 5em;
	color: white;
  }
  
  .compact .title {
	font-size: 1.5em;
  }
  